import React, { } from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <footer className="footer ahjud-footer">
    <div className="content">
      <div className="columns">
        <div className="column has-text-centered">
          <p>
            <strong className="is-size-4 ahjud-font-syncopate ">Ahjud Pro OÜ</strong>
            <br />
            <span className="is-size-6">Reg nr: 16141270</span>
            <br />
            <span className="is-size-6">Tel: +372 514 5930</span>
            <br />
            <span className="is-size-6"><a href="mailto:ahjudpro@gmail.com">ahjudpro@gmail.com</a></span>
          </p>
        </div>
      </div>

    </div>
  </footer>
);

export default Footer;
